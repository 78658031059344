<template>
  <v-container>
    <v-card class="mx-auto my-4" max-width="500" color="card">
      <v-card-title>
        404 Page Not Found
      </v-card-title>
      <v-card-text>
        The page you requested does not exist.
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
export default {
  metaInfo() {
    return {
      title: '404 Page Not Found | Metaview',
    }
  },
}
</script>
